<template>
  <div :class="containerClassList">
    <Balloon :text="message" :arrowPosition="balloonArrowPosition" />

    <label v-if="label" :for="id" :class="checkboxLabelClass">
      {{ label }}
    </label>
    <input
      :id="id"
      :class="checkboxClass"
      :type="inputType.CHECKBOX"
      :disabled="disabled"
      :name="name"
      v-model="innerValue"
      @input="handleInput($event.target.checked)"
      @focus="handleFocus"
    />
  </div>
</template>

<script>
import {
  BalloonArrowPosition,
  CSN_INPUT_CLASS,
  CHECKBOX_FIELD,
} from '@/constants'
import { inputMixin } from '@/mixins'

const CHECKBOX_CONTAINER_CLASS = 'form-check csn-form-checkbox-container'
const CHECKBOX_LABEL_CLASS = 'form-check-label'
const CHECKBOX_CLASS = 'form-control form-check-input'
const CHECKBOX_WITH_ERROR = 'csn-form-checkbox-with-error'

export default {
  name: CHECKBOX_FIELD,
  inheritAttrs: false,
  components: {
    Balloon: () => import('@/components/Balloon'),
  },
  mixins: [inputMixin],
  computed: {
    containerClassList() {
      return [CSN_INPUT_CLASS, CHECKBOX_CONTAINER_CLASS, this.containerClass]
    },
    checkboxLabelClass() {
      return [this.derivedLabelClass, CHECKBOX_LABEL_CLASS]
    },
    checkboxClass() {
      return [
        CHECKBOX_CLASS,
        this.inputClass,
        this.message && CHECKBOX_WITH_ERROR,
      ]
    },
    balloonArrowPosition: () => BalloonArrowPosition.BOTTOM_LEFT,
  },
}
</script>
